import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    people_cont: undefined,
    order_id: undefined,
    total: '0.00',
    order: {
        goods_list: [],
        total: '0.00'
    },
    cart: {
        goods_list: [],
        total: '0.00'
    },
    code: 200,
    message: ''
}


export const orderSlice = createSlice({
    name: 'order',
    initialState: { data: sessionStorage.getItem("menu") || initialState },
    reducers: {
        saveOrder(state, { payload }) {
            state.data = payload.data
            sessionStorage.setItem("order", payload.data)
        }
    }
})



// 导出action函数
export const { saveOrder } = orderSlice.actions


// 导出  reducer ， 创建store
export default orderSlice.reducer
