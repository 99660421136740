import React, { useMemo } from 'react'
import { Popup, Image, Button, Stepper, Toast } from 'antd-mobile'
import { AddOutline } from 'antd-mobile-icons'
import './index.scss'
const sumArray = (arr) => {
    var len = arr.length;
    if (len === 0) {
        return 0;
    } else if (len === 1) {
        return arr[0];
    } else {
        return arr[0] + sumArray(arr.slice(1));
    }
}
export default function MealView(props) {
    const { visible, dataSource, selected } = props

    // 校验是否已经全选择完全
    const disabled = useMemo(() => {
        let value = false
        dataSource?.set_meal_list.forEach(item => {
            const { index, max } = item
                , total = sumArray(selected.children.filter(r => r.index === index).map(r => r.num))
            if (total !== max) {
                value = true
            }
        })
        return value
    }, [selected, dataSource])


    return (
        <Popup
            className='meal-view'
            visible={visible}
            onMaskClick={() => {
                props.onClose(false)
            }}
            bodyStyle={{ height: '80vh' }}
            style={{ '--z-index': 1000 }}
        >
            {
                dataSource ? (
                    <>
                        <div className='image'>
                            <Image lazy src={dataSource.goods_img} height={'50vw'} />
                        </div>
                        <div className='operate'>
                            <h2>{dataSource.goods_name}</h2>
                            <Button
                                style={{ '--background-color': '#fadb14' }}
                                disabled={disabled}
                                onClick={() => {
                                    const params = { ...selected, children: selected.children.filter(row => row.num > 0) }
                                    props.onConfirm(params)
                                    props.onClose(false)
                                }}
                            >
                                <AddOutline />加入购物车
                            </Button>
                        </div>
                        <div
                            className='main'
                            style={{ height: `calc(80vh - 50vw - 40px)` }}
                        >
                            {
                                dataSource.set_meal_list.map(item => {
                                    const { content, detail, max, index } = item
                                    return (
                                        <div
                                            key={index}
                                            className='item'
                                        >
                                            <h4 className='item-title'>{content}</h4>
                                            <div
                                                className='item-list'
                                            >
                                                {
                                                    detail.map(row => {
                                                        const { sku_id, sku_name } = row
                                                            , record = selected.children.find(r => r.sku_id === sku_id)
                                                        return (
                                                            <div
                                                                key={sku_id}
                                                                className='item-cell'
                                                                value={sku_id}
                                                            >
                                                                {sku_name}
                                                                <Stepper
                                                                    min={0}
                                                                    max={99}
                                                                    inputReadOnly={true}
                                                                    style={{
                                                                        '--button-background-color': '#fadb14',
                                                                        '--button-text-color': '#000'
                                                                    }}
                                                                    value={record ? record.num : 0}
                                                                    onChange={num => {
                                                                        const children = record ? selected.children.map(r => r.sku_id === sku_id ? { ...r, num } : { ...r }) : [...selected.children, { index, sku_id, num }]
                                                                            , total = sumArray(children.filter(r => r.index === index).map(r => r.num))
                                                                        if (total <= max) {
                                                                            props.onSelect({ ...selected, children })
                                                                        } else {
                                                                            Toast.show({
                                                                                content: `${content}，已选择最大数量！`,
                                                                                position: 'bottom',
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                ) : null
            }
        </Popup>
    )
}
