import { createSlice } from "@reduxjs/toolkit";


const auth = sessionStorage.getItem("auth")
    , defaultAuth = { token: null, storeInfo: null, table_id: null }
export const AuthSlice = createSlice({
    name: 'auth',
    initialState: auth ? JSON.parse(auth) : defaultAuth,
    reducers: {
        setAuth(state, { payload }) {
            const { token, storeInfo, table_id } = payload
            state.token = token
            state.storeInfo = storeInfo
            state.table_id = table_id
            sessionStorage.setItem("auth", JSON.stringify(payload))
        },
        clear(state) {
            const { token, storeInfo, table_id } = defaultAuth
            state.token = token
            state.storeInfo = storeInfo
            state.table_id = table_id
            sessionStorage.setItem("auth", JSON.stringify(defaultAuth))
        }
    }
})

// 导出action函数
export const { setAuth, clear } = AuthSlice.actions


// 导出  reducer ， 创建store
export default AuthSlice.reducer
