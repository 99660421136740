import React, { useEffect, useState } from 'react'
import { FloatingPanel, Tabs, Image, List, Stepper, ErrorBlock, Mask, Modal } from 'antd-mobile'
import { DeleteOutline } from 'antd-mobile-icons'

import './index.scss'
export default function DetailsView(props) {
    const { visible, cart, order, activeKey } = props
        , ref = React.useRef(null)
        , anchors = [0, window.innerHeight * 0.5, window.innerHeight * 0.9]
        , [bodyHeight, setBodyHeight] = useState(0)

    useEffect(() => {
        if (visible) {
            ref.current.setHeight(window.innerHeight * 0.5)
        }
    }, [visible])


    return (
        <>
            <Mask
                visible={visible}
                style={{ '--z-index': 50 }}
                opacity='thick'
                onMaskClick={() => {
                    ref.current.setHeight(0)
                    props.onClose()
                }}
            />
            <FloatingPanel
                ref={ref}
                anchors={anchors}
                onHeightChange={(height) => {
                    if (height === 0) {
                        props.onClose()
                    }
                    setBodyHeight(height - 104 + 'px')
                }}

            >
                <Tabs
                    activeKey={activeKey}
                    style={{ '--content-padding': 0, '--height': bodyHeight }}
                    onChange={props.setActiveKey}
                    className='details-view'
                >
                    <Tabs.Tab
                        title={`购物车 (￥${cart.total})`}
                        key='cart'
                    >
                        {
                            cart.goods_list.length ? (
                                <div className='tips'>
                                    <h2>已选商品</h2>
                                    <p onClick={async () => {
                                        const result = await Modal.confirm({
                                            content: '此操作将清空购物车信息, 是否继续？'
                                        })
                                        if (result) {
                                            props.onCleared()
                                        }
                                    }}>
                                        <DeleteOutline /> 清空购物车
                                    </p>
                                </div>
                            ) : null
                        }
                        {
                            cart.goods_list.length ? (
                                <List mode='card'>
                                    {
                                        cart.goods_list.map(item => {
                                            const { sku_id, num, price, sku_name, goods_img } = item
                                            return (
                                                <List.Item
                                                    key={sku_id}
                                                    prefix={
                                                        <Image
                                                            src={goods_img}
                                                            fit='cover'
                                                            width={60}
                                                            height={60}
                                                        />
                                                    }
                                                    description={`￥${num * price}`}
                                                    extra={(
                                                        <Stepper
                                                            min={0}
                                                            max={99}
                                                            inputReadOnly={true}
                                                            style={{
                                                                '--button-background-color': '#fadb14',
                                                                '--button-text-color': '#000'
                                                            }}
                                                            value={num}
                                                            onChange={num => {
                                                                props.onCart({
                                                                    type: 'cart',
                                                                    num,
                                                                    sku_id
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                >
                                                    {sku_name}
                                                </List.Item>
                                            )
                                        })}
                                </List>
                            ) : (
                                <ErrorBlock
                                    image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                                    style={{
                                        '--image-height': '100px',
                                        marginTop: 20
                                    }}
                                    title={null}
                                    description={'购物车竟然是空的'}
                                />
                            )
                        }
                    </Tabs.Tab>
                    <Tabs.Tab
                        title={`已下单 (￥${order.total})`}
                        key='order'
                    >
                        {
                            order.goods_list.length ? (
                                <List mode='card'>
                                    {
                                        order.goods_list.map(item => {
                                            const { sku_id, num, price, sku_name, goods_img } = item
                                            return (
                                                <List.Item
                                                    key={sku_id}
                                                    prefix={
                                                        <Image
                                                            src={goods_img}
                                                            fit='cover'
                                                            width={60}
                                                            height={60}
                                                        />
                                                    }
                                                    description={`数量：${num} 单价：${price}`}
                                                    extra={`￥${num * price}`}
                                                >
                                                    {sku_name}
                                                </List.Item>
                                            )
                                        })}
                                </List>
                            ) : (
                                <ErrorBlock
                                    style={{
                                        '--image-height': '100px',
                                        marginTop: 20
                                    }}
                                    title={null}
                                    description={'您还未下单哦'}
                                />
                            )
                        }
                    </Tabs.Tab>
                </Tabs>
            </FloatingPanel>
        </>
    )
}
