import request from "../request";

// 获取店铺桌台信息
const storeInfo = (store_id, table_id) => request.get(`/v1/store/info/${store_id}/${table_id}`)

// 获取店铺菜单信息
const getGoods = () => request.get('/v1/goods/menu/tree')

// 新建购物车
const addCart = (people_cont) => request.post('/v1/orderinfo/cart', { people_cont })

const common = {
    storeInfo,
    getGoods,
    addCart
}

export default common
