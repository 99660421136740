import React from 'react'
import './index.scss'

export default function CartView(props) {
    const { money } = props
    return (
        <div
            className='cart'
        >
            <div
                onClick={() => props.onClick()}
                className='icon'
            >
                <svg t="1702790466548" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1998" width="70" height="70">
                    <path fill="#ffc53d" d="M455.168 644.096h179.2V609.28H474.624zM542.208 713.728c0 25.6 20.48 46.592 46.08 46.592s46.592-20.992 46.592-46.592c0-4.096-0.512-7.68-1.536-11.776h-89.6c-1.024 3.584-1.536 7.68-1.536 11.776zM355.84 667.136c-25.6 0-46.08 20.992-46.08 46.592s20.48 46.592 46.08 46.592 46.592-20.992 46.592-46.592-20.48-46.592-46.592-46.592zM428.032 597.504H366.08c-3.072 0-5.632-2.56-5.632-5.632 0-1.024 0-2.048 0.512-2.56l20.992-37.888h46.08l-71.68-151.04h-46.592v-46.592h75.776l22.016 46.592h257.536l12.8-23.552c2.048-3.072 3.072-7.168 3.072-11.264 0-12.8-10.24-23.04-23.04-23.04h-343.04l-22.016-46.592H216.576v46.592h46.592l83.456 176.128-31.232 56.832c-3.584 6.656-5.632 14.336-5.632 22.528 0 25.6 20.992 46.592 46.592 46.592H404.48c1.024-3.584 2.56-7.168 4.096-10.752l19.456-36.352z" p-id="1999"></path>
                    <path fill="#fff566" d="M680.96 725.504c-25.6 0-46.08 20.992-46.08 46.592s20.48 46.592 46.08 46.592 46.592-20.992 46.592-46.592c0-26.112-20.992-46.592-46.592-46.592zM449.024 725.504c-25.6 0-46.08 20.992-46.08 46.592s20.48 46.592 46.08 46.592 46.592-20.992 46.592-46.592c-0.512-26.112-20.992-46.592-46.592-46.592zM455.168 644.096l18.944-34.816h173.056c17.408 0 32.768-9.728 40.448-24.064l82.944-150.528c2.048-3.072 3.072-7.168 3.072-11.264 0-12.8-10.24-23.04-23.04-23.04h-343.04l-22.016-46.592H309.76v46.592h46.592l71.68 151.04 11.776 25.088-11.776 20.992-19.456 35.84c-2.048 3.584-3.072 7.168-4.096 10.752-1.024 3.584-1.536 7.68-1.536 11.776 0 25.6 20.992 46.592 46.592 46.592h279.04v-46.592H458.752c-3.072 0-5.632-2.56-5.632-5.632 0-1.024 0-2.048 0.512-2.56l1.536-3.584z" p-id="2000"></path>
                    <path fill="#ffd666" d="M223.232 696.32h-29.696v-29.696c0-6.656-5.12-11.776-11.776-11.776s-11.776 5.12-11.776 11.776v29.696h-29.696c-6.656 0-11.776 5.12-11.776 11.776 0 6.656 5.12 11.776 11.776 11.776h29.696v29.696c0 6.656 5.12 11.776 11.776 11.776s11.776-5.12 11.776-11.776v-29.696h29.696c6.656 0 11.776-5.12 11.776-11.776-0.512-6.656-5.632-11.776-11.776-11.776z" p-id="2001"></path>
                    <path fill="#feffe6" d="M172.032 437.248h65.536v65.536H172.032z" p-id="2002"></path>
                    <path fill="#fffbe6" d="M861.696 655.36m-32.768 0a32.768 32.768 0 1 0 65.536 0 32.768 32.768 0 1 0-65.536 0Z" p-id="2003"></path>
                    <path fill="#fadb14" d="M825.856 298.496l-89.088-89.088c-3.072-3.072-8.192-4.096-12.8-2.56-4.096 2.048-7.168 6.144-7.168 10.752v89.088c0 3.072 1.536 6.144 3.584 8.192 2.048 2.048 5.12 3.584 8.192 3.584h89.088c4.608 0 8.704-3.072 10.752-7.168 1.536-4.608 0.512-9.728-2.56-12.8z m-86.016-3.584V245.76l49.152 49.152h-49.152z" p-id="2004"></path>
                </svg>
            </div>
            <div
                onClick={() => props.onClick()}
                className='info'
            >￥{money}</div>
            <div
                onClick={() => {
                    props.onConfirm()
                }}
                className='btn'>
                下单
            </div>
        </div>
    )
}
