import React, { useMemo } from 'react'
import { Popup, Space, Tag, Image, Button, Stepper } from 'antd-mobile'
import { AddOutline } from 'antd-mobile-icons'

import './index.scss'

export default function SpecView(props) {
    const { visible, dataSource = { pro_list: [] }, selected, value = [] } = props

    const number = useMemo(() => {
        if (selected && value) {
            const record = value.find(v => v.sku_id === selected.sku_id)
            return record?.num || 0
        } else {
            return 0
        }
    }, [value, selected])

    return (
        <Popup
            className='spec-view'
            visible={visible}
            onMaskClick={() => {
                props.onClose(false)
            }}
            bodyStyle={{ height: '50vh' }}
            style={{ '--z-index': 3000 }}
        >
            <div className='selected-image'>
                <Image
                    src={selected?.goods_img}
                    width={80}
                    height={80}
                />
            </div>
            <div style={{ minHeight: 'calc(50vh - 62px)', padding: '8px 16px 16px' }}>
                <div className='selected-info'>
                    <div>{selected?.sku_name}</div>
                    <div>￥{selected?.price}</div>
                </div>
                {
                    dataSource?.pro_list.map(item => {
                        const { id, name, value = [] } = item
                        return (
                            <div
                                key={id}
                            >
                                <h2 style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid var(--adm-color-border)' }}>{name}</h2>
                                <Space>
                                    {
                                        value.map(row => {
                                            const { sku: { sku_id, sku_name } } = row
                                            return (
                                                <Tag
                                                    key={sku_id}
                                                    style={{ padding: '5px 6px' }}
                                                    color={selected?.sku_id === sku_id ? 'warning' : 'default'}
                                                    onClick={() => {
                                                        if (selected?.sku_id !== sku_id) {
                                                            props.onSelect({ ...row.sku, num: 0 })
                                                        }
                                                    }}
                                                >
                                                    {sku_name}
                                                </Tag>
                                            )
                                        })
                                    }
                                </Space>
                            </div>
                        )
                    })
                }
            </div>

            {
                number ? (
                    <div className='operate'>
                        <div>总计：￥{selected?.num * Number(selected?.price)}</div>
                        <Stepper
                            min={0}
                            max={99}
                            inputReadOnly={true}
                            style={{
                                '--button-background-color': '#fadb14',
                                '--button-text-color': '#000'
                            }}
                            value={number}
                            onChange={num => {
                                if(num){
                                    props.onSelect({ ...selected, num })
                                }else{
                                    props.onSub(selected)
                                }
                            }}
                        />
                    </div>
                ) : (
                    <div className='btn'>
                        <Button
                            style={{ '--background-color': '#fadb14' }}
                            onClick={() => {
                                props.onSelect({ ...selected, num: 1 })
                            }}
                        >
                            <AddOutline />加入购物车
                        </Button>
                    </div>
                )
            }
        </Popup>
    )
}
