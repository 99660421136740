import { configureStore } from '@reduxjs/toolkit';
import authSlice from './modules/auth';
import orderSlice from './modules/order';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        order: orderSlice,
    }
})
