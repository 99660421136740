import React, { useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { Toast, SpinLoading } from 'antd-mobile'
import { useLocation } from 'react-router-dom';
import { common } from '../../service';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../store/modules/auth';
import { saveOrder } from '../../store/modules/order';
import BeginView from '../begin'
import CartView from '../cart';
import GoodsView from '../goods';
import DetailsView from '../details';
import './index.scss'
export default function HomeView() {
    const { search } = useLocation()
        , dispatch = useDispatch()
        , { token, storeInfo } = useSelector(state => state.auth)
        , { data: { people_cont, order_id, cart, order, total } } = useSelector(state => state.order)
        , [loading, setLoading] = useState(false)
        , [dataSource, setDataSource] = useState([])                    // 商品列表
        , [activeKey, setActiveKey] = useState(null)                    // 商品列表选中的类型
        , [detailsVisible, setDetailsVisible] = useState(false)        // 购物车，订单详情
        , [detailsKey, setDetailsKey] = useState('cart')

    const url = useMemo(() => {
        if (token) {
            const { table_id } = storeInfo
            // return `wss://adminapi.nuoman.com.cn/ws/phone/order/${table_id}/${token}`
            return `wss://adminapi.nuoman.com.cn/ws/phone/order/${table_id}/${token}`
        } else {
            return null
        }
    }, [token, storeInfo])

    const { sendMessage, lastMessage, readyState } = useWebSocket(url, {
        onOpen: () => sendMessage(JSON.stringify({ type: "orderinfo" })),
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 10,
        reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    });

    useEffect(() => {
        if (token) {
            setLoading(true)
            // 获取店铺菜单信息
            common.getGoods().then(({ data }) => {
                setDataSource(data)
                if (data.length) {
                    setActiveKey('' + data[0].id)
                }
                setLoading(false)
            }).catch(() => {
                Toast.show({
                    icon: 'fail',
                    content: '获取菜单信息失败',
                })
            })
            // 设置浏览器标题
            const { shop_name, table_name } = storeInfo
            document.title = `${shop_name} 第${table_name}桌`
        } else {
            // 获取店铺桌台信息
            const params = new URLSearchParams(search)
                , store_id = params.get('store_id')
                , table_id = params.get('table_id')

            if (store_id && table_id) {
                common.storeInfo(store_id, table_id).then(({ data: { token, storeInfo } }) => {
                    dispatch(setAuth({ token, storeInfo, table_id }))
                }).catch((error) => {
                    Toast.show({
                        icon: 'fail',
                        content: error.message,
                    })
                })
            }
        }
    }, [token, storeInfo, search, dispatch])

    // 长连接
    useEffect(() => {
        if (lastMessage && readyState) {
            const { data } = lastMessage
            const order_info = JSON.parse(data)
            console.log(order_info)
            dispatch(saveOrder({ data: order_info }))
            setLoading(false)
            if(order_info.code !== 200){
                setDetailsKey('cart')
                Toast.show({
                    content: order_info.message,
                    afterClose: () => {
                        console.log('after')
                    },
                })
            }
        } else {
            // 等待长链接或空数据
        }
    }, [lastMessage, readyState, dispatch])

    return people_cont === 0 ? (
        <div>
            <BeginView
                dataSource={storeInfo}
                onConfirm={(people_cont) => {
                    common.addCart(people_cont).then(res => {
                        Toast.show({
                            content: '可以开始点菜啦！',
                            afterClose: () => {
                                console.log('after')
                            },
                        })
                    }).catch(() => {
                        Toast.show({
                            content: '创建购物车失败，请联系客服！',
                            afterClose: () => {
                                console.log('after')
                            },
                        })
                    })
                }}
            />
        </div>
    ) : (
        <div>
            {
                loading ? (
                    <div className='loading'>
                        <SpinLoading color='primary' />
                    </div>
                ) : null
            }
            <GoodsView
                banner={storeInfo?.banner}
                dataSource={dataSource}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                height="calc(100vh - 70px)"
                value={cart.goods_list}
                onCart={(params) => {
                    setLoading(true)
                    console.log(params)
                    sendMessage(JSON.stringify({ ...params, order_id }))
                }}
            />

            <DetailsView
                order={order}
                cart={cart}
                visible={detailsVisible}
                activeKey={detailsKey}
                setActiveKey={setDetailsKey}
                onClose={() => setDetailsVisible(false)}
                onCart={(params) => {
                    setLoading(true)
                    sendMessage(JSON.stringify({ ...params, order_id }))
                }}
                onCleared={() => {
                    setLoading(true)
                    sendMessage(JSON.stringify({ type: 'cleared' }))
                }}
            />

            <CartView
                money={total}
                height={70}
                onClick={() => {
                    setDetailsKey('cart')
                    setDetailsVisible(value => !value)
                }}
                onConfirm={() => {
                    setLoading(true)
                    sendMessage(JSON.stringify({ type: 'order' }))
                    setDetailsVisible(true)
                    setDetailsKey('order')
                }}
            />
        </div>
    )
}
