import React, { useState } from 'react'
import { Button, Image, NumberKeyboard, Toast } from 'antd-mobile'
import { AddOutline } from 'antd-mobile-icons'


import './index.scss'
export default function BeginView(props) {
    const { dataSource } = props
        , [value, setValue] = useState('')

    return (
        <div className='begin-view'>
            <Image
                width={'100vw'}
                src={dataSource?.logo}
            />
            {/* <h2>欢迎光临{dataSource?.shop_name}</h2> */}
            <div className='main'>
                <div className='table-name'>{value || 0}人</div>

                {/* <p className='tips'>请选择正确的用餐人数</p> */}
                <div className='list'>
                    {/* <VirtualInput
                        style={{ width: 'calc(100vw - 20px)' }}
                        placeholder='请选择正确的用餐人数'
                        clearable
                        keyboard={<NumberKeyboard />}
                    /> */}
                    <NumberKeyboard
                        title='用餐人数'
                        visible={true}
                        confirmText='确定'
                        onInput={(v) => {
                            setValue((state) => state + v)
                        }}
                        onDelete={() => {
                            setValue(str => str.slice(0, -1))
                        }}
                        onConfirm={() => {
                            if (value) {
                                props.onConfirm(value)
                            } else {
                                Toast.show({
                                    content: '请选择正确的用餐人数'
                                })
                            }
                        }}
                    />

                </div>
                <div className='beian'>
                    <a style={{ color: '#999' }} href="https://beian.miit.gov.cn/" target="_blank">皖ICP备 2023021515号-1</a>
                </div>
                <div>
                    <Button
                        disabled={value === 0}
                        style={{ '--background-color': '#fadb14' }}
                        onClick={() => {
                            props.onConfirm(value)
                        }}
                    >
                        <AddOutline />开始点菜
                    </Button>
                </div>
            </div>
        </div >
    )
}
