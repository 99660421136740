import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeView from './home';

export default function LayoutView() {
    return (
        <Routes>
            <Route path="/*" element={<HomeView />} />
        </Routes>
    )
}
