import React, { useEffect, useMemo, useState } from 'react'
import { SideBar, Space, Image, List, Stepper, Button, Badge, Swiper } from 'antd-mobile'
import { useThrottleFn } from 'ahooks'
import SpecView from '../spec'
import MealView from '../meal'

import './index.scss'


export default function GoodsView(props) {
    const { dataSource, height = '100vh', value = [], activeKey, setActiveKey, banner = [] } = props
        , [specVisible, setSpecVisible] = useState(false)       // 规格窗口是否显示
        , [specData, setSpecData] = useState(null)              // 当前待选择规格的商品数据
        , [specSelected, setSpecSelected] = useState(null)      // 默认已选的规格商品数据
        , [mealVisible, setMealVisible] = useState()            // 套餐窗口是否显示
        , [mealData, setMealData] = useState()                  // 当前套餐商品数据
        , [mealSelected, setMealSelected] = useState(null)      // 套餐配置选择数据
        , mainElementRef = React.useRef(null)
        , tabs = dataSource.map(({ id, cate_name, cate_name_ot }) => ({
            key: '' + id,
            title: (
                <Space direction="vertical">
                    {cate_name}
                    {cate_name_ot || ''}
                </Space>
            )
        }))

    const { run: handleScroll } = useThrottleFn(() => {
        let currentKey = tabs[0].key;
        for (const item of tabs) {
            const element = document.getElementById(`anchor-${item.key}`);
            if (!element)
                continue;
            const rect = element.getBoundingClientRect();
            if (rect.top <= 0) {
                currentKey = item.key;
            }
            else {
                break;
            }
        }
        setActiveKey(currentKey);
    }, {
        leading: true,
        trailing: true,
        wait: 100,
    });

    useEffect(() => {
        const mainElement = mainElementRef.current;
        if (!mainElement)
            return;
        mainElement.addEventListener('scroll', handleScroll);
        return () => {
            mainElement.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    // 监控 选择规格 返回默认选中的一项规格行数据
    useEffect(() => {
        if (specVisible && specData) {
            specData.pro_list.forEach(item => {
                item.value.forEach(row => {
                    if (row.is_default) {
                        setSpecSelected({ ...row.sku, num: 0 })
                    }
                })
            })
        }
    }, [specVisible, specData])

    // 当规格行数据选中的数量大于0时，提交添加商品的操作
    useEffect(() => {
        if (specSelected && specSelected.num > 0) {
            const { num, sku_id } = specSelected
            props.onCart({
                type: 'spec',
                num,
                sku_id
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specSelected])

    const SpecValue = useMemo(() => {
        if (specVisible && specData) {
            const record = value.find(v => v.sku_id === specData.sku_id)
            return record ? record.children : []
        } else {
            return []
        }
    }, [specVisible, specData, value])


    return (
        <>
            <div
                className="container"
                style={{ height }}
            >
                <div className="side">
                    <SideBar
                        activeKey={activeKey}
                        onChange={key => {
                            var _a;
                            (_a = document.getElementById(`anchor-${key}`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
                        }}
                    >
                        {
                            tabs.map(item => (
                                <SideBar.Item
                                    key={item.key}
                                    title={item.title}
                                />
                            ))
                        }
                    </SideBar>
                </div>
                <div className="main" ref={mainElementRef}>
                    {
                        dataSource.map((item, index) => {
                            const { cate_name, cate_name_ot, icon, id, goods_list } = item
                            return (
                                <div style={{ marginBottom: 20 }} key={id}>
                                    {
                                        index === 0 ? (
                                            <Swiper style={{ marginTop: 10 }}>
                                                {
                                                    banner.map((item, key) => {
                                                        return (
                                                            <Swiper.Item key={key}>
                                                                <Image
                                                                    lazy={true}
                                                                    width={'calc(100vw - 16px - 105px)'}
                                                                    height={182}
                                                                    src={item.image}
                                                                />
                                                            </Swiper.Item>
                                                        )
                                                    })
                                                }
                                            </Swiper>
                                        ) : null
                                    }

                                    <div className='title' id={`anchor-${id}`}>
                                        <Space>{cate_name} {cate_name_ot}</Space>
                                        {
                                            icon ? (
                                                <Image
                                                    src={icon}
                                                    fit='cover'
                                                    width={20}
                                                    height={20}
                                                />
                                            ) : null
                                        }
                                    </div>
                                    <List
                                        key={id}
                                    >
                                        {
                                            goods_list.map(row => {
                                                const { sku_id, goods_img, goods_price, goods_name, goods_desc, pro_list, is_sell_out, is_set_meal } = row
                                                // is_sell_out 售罄
                                                // is_set_meal 套餐
                                                // console.log(index, sku_id)
                                                const record = value.find(v => v.sku_id === sku_id)
                                                return (
                                                    <List.Item
                                                        title={<span style={{ color: '#333' }}>{goods_name}</span>}
                                                        key={`${id}-${sku_id}`}
                                                        id={sku_id}
                                                        prefix={(
                                                            <div style={{ margin: '8px 0' }}>
                                                                <Image
                                                                    lazy
                                                                    src={goods_img}
                                                                    fit='cover'
                                                                    width={80}
                                                                    height={80}
                                                                />
                                                            </div>
                                                        )}
                                                        description={(
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <div style={{ color: '#f5222d', fontSize: 14 }}>￥{goods_price}</div>
                                                                {
                                                                    is_sell_out ? pro_list.length ? (
                                                                        <Badge
                                                                            content={record?.num || null}
                                                                            style={{ '--right': '100%' }}
                                                                        >
                                                                            <Button
                                                                                size='mini'
                                                                                style={{ '--background-color': '#fadb14' }}
                                                                                onClick={() => {
                                                                                    setSpecVisible(true)
                                                                                    setSpecData(row)
                                                                                }}
                                                                            >
                                                                                选规格
                                                                            </Button>
                                                                        </Badge>
                                                                    ) : is_set_meal ? (
                                                                        <Button
                                                                            size='mini'
                                                                            style={{ '--background-color': record?.num ? '#f5222d' : '#fadb14' }}
                                                                            onClick={() => {
                                                                                setMealVisible(true)
                                                                                setMealData(row)
                                                                                setMealSelected({
                                                                                    type: "setmeal",
                                                                                    sku_id: row.sku_id,
                                                                                    num: 1,
                                                                                    children: record?.children || []
                                                                                })
                                                                            }}
                                                                        >
                                                                            {
                                                                                record?.num ? (
                                                                                    <span style={{ color: 'white' }}>套餐已选</span>
                                                                                ) : '选套餐'}
                                                                        </Button>
                                                                    ) : (
                                                                        <Stepper
                                                                            min={0}
                                                                            max={99}
                                                                            inputReadOnly={true}
                                                                            style={{
                                                                                '--button-background-color': '#fadb14',
                                                                                '--button-text-color': '#000'
                                                                            }}
                                                                            value={record?.num || 0}
                                                                            onChange={num => {
                                                                                props.onCart({
                                                                                    type: 'cart',
                                                                                    num,
                                                                                    sku_id
                                                                                })
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            size='mini'
                                                                            disabled={true}
                                                                        >
                                                                            售罄
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )}
                                                    >
                                                        <span style={{ fontSize: 12, color: '#999' }}>{goods_desc}</span>
                                                    </List.Item>
                                                )
                                            })
                                        }
                                    </List>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <SpecView
                visible={specVisible}
                dataSource={specData}
                selected={specSelected}
                onSelect={setSpecSelected}
                onSub={({ sku_id }) => {
                    props.onCart({
                        type: 'spec',
                        num: 0,
                        sku_id
                    })
                }}
                value={SpecValue}
                onClose={() => {
                    setSpecVisible(false)
                    setSpecData(null)
                }}
            />

            <MealView
                visible={mealVisible}
                dataSource={mealData}
                selected={mealSelected}
                onSelect={setMealSelected}
                onConfirm={(params) => {
                    props.onCart(params)
                }}
                onClose={() => {
                    setMealSelected(null)
                    setMealVisible(false)
                    setMealData(null)
                }}
            />
        </>
    )
}
