import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Pages from './pages';
import './assets/minireset.scss';
import './assets/redefine.scss';
import './assets/style.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Pages />}></Route>
            </Routes>
        </BrowserRouter>
    )
}

